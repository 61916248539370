$line-width: 25px;

.upgrade-lines {
    span {
        margin-left: -7px;
    }

    svg {
        width: $line-width;
    }

    &.list {
        padding-right: 0.5rem;
        padding-left: 0.5rem;

        svg {
            width: 13px;
        }

        span {
            margin-left: -4px;
        }

        @include media-breakpoint-up(sm) {
            svg {
                width: 16px;
            }
        }

        @include media-breakpoint-up(md) {
            svg {
                width: 12px;
            }
        }

        @include media-breakpoint-up(lg) {
            svg {
                width: 15px;
            }
        }

        @include media-breakpoint-up(xl) {
            svg {
                width: 13px;
            }
        }

        @include media-breakpoint-up(xxl) {
            svg {
                width: 14px;
            }
        }
    }

    &.detail {
        margin-left: 1rem;
    }


    .disabled {
        opacity: 0.05;
    }
}

.param_speed_color {
    color: $param-gold;

    svg {

        fill: $param-gold;
    }
}

.param_acceleration_color {
    color: $param-green;
    fill: $param-green;
}

.param_handling_color {
    color: $param-blue;
    fill: $param-blue;
}

.param_grip_color {
    color: $param-purple;
    fill: $param-purple;
}

.param_durability_color {
    color: $param-red;
    fill: $param-red;
}

/**
* Bike Card
*/


@each $rarity,
$color in $rarities {
    .#{$rarity}-color {
        color: $color;
    }
}

.bike-box {
    border-radius: $border-radius-lg;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(221, 221, 221, 1) 100%);
    overflow: hidden;

    a {
        text-decoration: none;
    }

    .card-img {
        width: 100%;
        height: 100%;

        .bike-img {
            transition: all 0.3s ease-in-out;
            width: 100%;
            height: 100%;
        }
    }

    .bike-number {
        border: 1px solid #000;
        background: #ffffff;
        width: 90px;
        position: absolute;
        top: 8px;
        right: 8px;
        border-radius: $border-radius-lg;

        .company {
            background: #000;
            color: #fff;
            font-size: 9px;
            text-align: center;
            font-style: italic;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        .number {
            color: $black;
            text-align: center;
            font-weight: bold;
            font-size: 1.2rem;
        }

        @each $rarity,
        $color in $rarities {
            &.#{$rarity} {
                border-color: $color;

                .company {
                    background: $color;
                }

                .number {
                    color: $color;
                }
            }
        }
    }

    .bike-forsale {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .build-new-box {
        display: inline-block;
    }

    .spinner-border {
        color: #000000;
    }

    .check_mark {
        display: none;
    }

    .info {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $see-through;
        color: $grey-light;
        display: none;

        &.show {
            display: flex;
        }
    }

    &.select {
        border: 2px solid $red;

        .info {
            display: flex;
        }

        .check_mark {
            z-index: 10;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.disabled {
        .info {
            display: flex;
        }

        cursor: auto;
    }

    .bike-specs-card {
        transition: all 0.3s ease-in-out;
        position: relative;
        bottom: 0;

        filter: saturate(10%);
        opacity: 0.5;


        //width: 80%;
        .icon {
            height: 20px;
        }

        .value {
            text-decoration: none;
            margin-left: 0.3rem;
            vertical-align: middle;
        }

        .col {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    &:hover .bike-specs-card {
        filter: saturate(100%);
        opacity: 1;
    }

    &:hover .bike-img {
        transform: scale(1.05);
    }

    .tier {
        background: url("../../src/assets/tier_icon.svg");
        height: 31px;
        position: absolute;
        top: 8px;
        left: 8px;
        background-size: contain;
        background-repeat: no-repeat;
        width: 23px;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        color: #000;
        padding-top: 1px;
    }
}

.bike-box:hover {
    .info {
        display: flex;
    }
}


.bike-specs {
    h3 {
        color: #000;
        font-weight: 400;
        font-size: 0.7rem;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    img.parameters {
        height: 28px;
    }

    img.mparts {
        height: 20px;
    }

    .param_value {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .tier_value {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .price_value {
        font-size: 1.5rem;
    }

    button {
        svg {
            fill: currentColor;
            height: 17px;
            margin-left: 5px;
        }
    }

}


/**
* Activity
*/

.limited-icon {
    position: absolute;
    width: 70px;
}

.background-image {
    background-size: cover;
    background-position: center;
}

.faded-background {
    @extend .background-image;
}

.faded-background::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
}


/**
 * Animation
 **/

.opacity-trigger {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.hide {
        opacity: 0;
    }
}

.rotating-element {
    /* Set the animation properties */
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate {

    /* Define the animation keyframes */
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}