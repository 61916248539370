.wp-content {
    --wp--preset--spacing--40: 2.5rem;

    img {
        max-width: 100%;
        height: auto;
    }
}

.wp-block-gallery {
    @extend .row;
    justify-content: center;

    .wp-block-image {
        @extend .col-12;
        @extend .col-md-6;
        @extend .col-lg-4;
    }
}

.wp-block-image {
    @extend .center;
}

.wp-block-columns {
    @extend .row;

    .wp-block-column {
        @extend .col;
    }
}

.wp-block-quote {
    @extend .blockquote;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    border-left: 2px solid;
}

.wp-block-button {
    @extend .btn;
    @extend .btn-primary;
    @extend .text-uppercase;
}

.wp-block-button__link,
.wp-block-button__link:hover,
.wp-block-button__link:focus,
.wp-block-button__link:active {
    color: $white;
    text-decoration: none;
}