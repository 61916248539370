@import "../node_modules/bootstrap/scss/functions";

@import "styles/variables";

@import "../node_modules/bootstrap/scss/variables";

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);
@import "../node_modules/bootstrap/scss/bootstrap";

$white: #fff;

@import "styles/wordpress";
@import "styles/whitepaper";
@import "styles/bike";

body,
#root {
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding: 0;
  background: #fafafa;
}

#root {
  display: flex;
  flex-direction: column;
}

.break-word {
  word-break: break-all;
}

.btn-inline,
.btn-inline:focus,
.btn-inline:active {
  box-shadow: none;
  margin: 0;
  padding: 0;
}

$twitter: #0f1419;

.btn-twitter,
.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active {
  border: 1px solid $twitter;
  color: $twitter;
  font-family: "TwitterChirp", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

//BARVY

.bg-red {
  background: $red;
}

.bg_dark {
  background: $blue-dark;
}

.color-red {
  color: red;
}

.color-grey {
  color: $grey-dark;
}

.w-unset {
  width: unset;
}

h2 {
  text-transform: uppercase;
  font-size: 1rem;
  color: $black;
  font-weight: bold;
  margin-bottom: 1.5rem;

  &.account_adress {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
  }
}

.discordLogo {
  color: #5E60F5;
}

.twitterLogo {
  color: $twitter;
}

.facebookLogo {
  color: #4267B2;
}

.instagramLogo {
  color: #fb3958;
}

.bold {
  font-weight: bold;
}

.infoBanner {
  background: $param-red;
  padding-top: 0.1rem;
  color: #fff;
  text-align: center;
  letter-spacing: 0.04rem;
  padding-bottom: 0.5rem;

  a {
    color: #fff;
  }
}

.headerBox {
  //background: white;
  //box-shadow: 0 2px 12px -1px rgba(0, 0, 0, 0.07);
  margin-bottom: 3rem;

  nav {
    padding: 0;
  }

  .logo {
    width: 200px;
    height: auto;
  }

  .logo-stripe {
    height: 60px;
    width: auto;
  }

  .nav-link {
    text-transform: uppercase;
    color: $black;
    font-weight: bold;
    transition: none;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-bottom: 3px solid transparent;
    font-size: 0.9rem;

    &.active,
    &:hover {
      border-bottom: 3px solid $red;
    }
  }

  .badge {
    font-weight: normal;
  }
}

@keyframes sprite {
  from {
    background-position: 0 0%;
  }

  to {
    background-position: 0 100%;
  }
}

.bike_animation {
  position: relative;
  width: 100%;
  margin: 0% auto 0 auto;

  /* positioning tweak */
  &:before {
    content: "";
    display: block;
    //padding-top: 56.25%;
    padding-top: calc(500 / 800 * 100%);
  }

  .bike_an {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: url("assets/moto_animation.png") no-repeat 0 0%;
    background-size: 100%;
    animation: sprite 15s steps(37) infinite;
  }
}


.card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  border: none;

  .center_button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}



.hero_divider {
  width: 100%;
  height: 49px;
  margin-top: -2px;

  background: url("../src/assets/race_stripe.svg");

  &.flip {
    transform: scaleY(-1);
  }
}

.hero_box {
  //background-image: url("../src/assets/bg_asphalt.jpg");
  background-position: center;
  background-size: cover;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;


  .herotext {
    text-align: center;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;

    @include media-breakpoint-up(sm) {
      font-size: 4rem;
    }
  }

  .login {
    text-align: center;

    h1 {
      font-size: 2rem;
      font-weight: 700;

      @include media-breakpoint-up(sm) {
        font-size: 3rem;
      }
    }
  }


  //.hero_yellow_moto {
  //  display: none;
  //}
  img {
    max-width: 80%;
    margin-top: 3rem;
  }

  .lead {
    font-size: $font-size-base;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 0rem;

    .lead {
      font-size: $lead-font-size;
    }
  }


  @include media-breakpoint-up(lg) {
    .herotext {
      text-align: left;
    }

    img {
      max-width: 100%;
      margin-top: 0rem;
    }

    //.hero_yellow_moto {
    //  position: absolute;
    //  bottom: 40px;
    //  right: 0;
    //  height: auto;
    //  width: 430px;
    //  display: block;
    //}

  }

  @include media-breakpoint-up(xl) {

    //.hero_yellow_moto {
    //  width: 630px;
    //}

  }
}

.helmet {
  max-width: 100px;
  height: auto;
}

.smallHelmet {
  max-width: 67px;
  height: auto;
}

.level_number {
  text-align: left;

  span {
    font-weight: bold;
    font-size: 1.1rem;
  }
}

.dashboard_section_header {
  font-size: 0.8rem;
  color: $grey-dark;
  margin-top: 0.5rem;
  font-weight: bold;
}

.dashboard_user_info {
  text-align: center;

  .name {
    @extend .dashboard_section_header;
  }

  .value {
    font-size: 1.7rem;
    font-weight: bold;
  }

  .desc {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
}


$trophy-height: 150px;

.trophy {
  min-height: $trophy-height;
  background-size: cover;
  width: 100%;

  img {
    max-height: $trophy-height;
  }
}

$activit-modal-height: 300px;

.activity-modal-header {
  min-height: $activit-modal-height;
  background-size: cover;
  width: 100%;

  img {
    max-height: $activit-modal-height;
  }
}

.card_bike_mini {
  width: 180px;
  height: auto;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.card_countdown {
  padding: 0.2rem 0.5rem;
  color: var(--bs-white);
  font-weight: bold;
  left: 50%;
  bottom: 5%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: var(--bs-secondary);
}

.spinner-custom {}

.metamask-logo {
  height: auto;
  width: 2rem;
  margin-top: 0 !important;
}

.test-banner {
  background-color: #7F7F7F;
}

.features {
  h2 {
    font-size: 2.5rem;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
  }

  .card {
    text-align: center;
    padding: 0.5rem;

    h4 {
      font-weight: bold;
    }

    .paramImg {
      max-width: 80%;
    }

    .card-footer {
      background: none;
      border: none;
    }
  }
}

.hp_white {
  background: #fff;
}

.skidmark {
  text-align: center;

  img {
    max-height: 110px;
    max-width: 100%;
  }

  &.flip {
    transform: scaleY(-1);
  }
}

.howitworks {
  h2 {
    font-size: 2.5rem;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
  }

  .card {
    text-align: center;
    box-shadow: none;
    border: none;
    background: transparent;
    padding: 1rem;

    h4 {
      font-weight: bold;
    }
  }
}

.features-hr,
.dashboard-hr {
  margin-top: 4rem;
  margin-bottom: 4rem;
  background: transparent;
}

.tuning3 {
  max-width: 90px;
}

.mparts {
  fill: currentColor;
}

.modal {
  .confetti-modal {
    min-height: 400px;

    .box {
      text-align: center;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 60%;
      position: absolute;
      min-width: 250px;

      .m-title {
        font-size: 2.75rem;
        font-weight: bold;
      }

      .m-text {
        font-size: 1.4rem;
      }
    }
  }
}

.hide-plain-bike {
  height: 0;
  overflow: hidden;
}

.tune-up-container {
  position: absolute;
  left: 0;
  top: 0;

  img {
    max-width: 50%;
  }
}

.coming-soon {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-bottom: 3rem;
  margin-top: 3rem;
  text-align: center;

  h1 {
    font-size: 3rem;
  }

  img {
    max-width: 50%;
  }

  .btn {
    background: #5E60F5;
  }
}

.footerBox {
  margin-top: auto;
  text-align: center;
  background: #fff;
  padding: 2rem;
  box-shadow: 0 2px 12px -1px rgb(0 0 0 / 7%);

  .logo {
    width: 200px;
    height: auto;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 2rem;
  }

  a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
  }
}

.avax-logo-mint {
  @extend .mx-1;
  width: 1rem;
  height: auto;
}

.token-icon {
  width: 2rem;
  height: auto;
}

.half-hero-image {
  width: 45%;
  height: auto;
}

.roadmapWrapper {
  img {
    display: none;

    @include media-breakpoint-up(xl) {
      display: inline;
    }
  }

  .roadmapBox {
    h4 {
      text-align: center;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    &>div:nth-of-type(1) {
      h4 {
        color: #4cb396;
      }

      ul>li::marker {
        color: #4cb396;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 2rem;
      }
    }

    &>div:nth-of-type(2) {
      h4 {
        color: #47b3da;
      }

      ul>li::marker {
        color: #47b3da;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 5rem;
      }
    }

    &>div:nth-of-type(3) {
      h4 {
        color: #de6d19;
      }

      ul>li::marker {
        color: #de6d19;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 3rem;
      }
    }

    &>div:nth-of-type(4) {
      h4 {
        color: #cd371f;
      }

      ul>li::marker {
        color: #cd371f;
      }

      @include media-breakpoint-up(xl) {
        margin-top: 6rem;
      }
    }
  }
}

//////////
// HELPERS
//////////

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1
}

.faded {
  opacity: 0.4;
}


.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

//////////
// SPINNER
//////////

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 100px;
  height: 100px;
  margin: 1rem;

  & .path {
    stroke: $primary;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}