$transition-duration: 0.5s;
$switch-size: 40px;

.whitepaper-margin {
    margin-right: $switch-size;

}

.whitepaper-nav {
    @extend .whitepaper-margin;
    padding: 0 calc($switch-size / 2);

    width: 320px;
    transition: width $transition-duration, padding $transition-duration, height $transition-duration;
    align-items: center;
    box-shadow: 0 0px 12px -1px rgb(0 0 0 / 7%);
    position: relative;

    .navbar-nav {
        overflow: hidden;
        width: 100%;
    }

    button {
        text-align: left;
    }

    .whitepaper-nav-switch {
        @extend .center;
        width: $switch-size;
        height: $switch-size;
        position: absolute;
        background: white;

        top: $switch-size;
        right: calc(($switch-size / 2) * -1);
        transition: transform $transition-duration, right $transition-duration, opacity $transition-duration;
    }


    &.is-collapsed {
        width: 0;
        height: 0;
        padding: 0;


        .whitepaper-nav-switch {
            right: -$switch-size;
            transform: rotate(180deg);
            opacity: 0.4;
        }

        .whitepaper-nav-switch:hover {
            opacity: 1;
        }
    }

}