$red: #e10600;
$green: #4cae04;
$grey-dark: #808080;
$grey-logo: #4c5560;
$grey-light: #DDDDDD;
$blue-dark: #12151C;

$see-through: rgba(8, 8, 9, 0.6);

$param-gold: #775200;
$param-green: #386500;
$param-blue: #004478;
$param-purple: #480078;
$param-red: #780E00;

// Create your own map
$custom-colors: ("primary": $red,
        "green": $green);

$link-color: $red;

$primary: $red;

$rarities: (Common: #000000,
        Uncommon: #286F2C,
        Rare: #2F8CCD,
        Epic: #9217CA,
        Legendary: #BA1A23,
        Heirloom: #BAA122);